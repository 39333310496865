.leaflet-container {
  height: 100vh;
  width: 100vw;
}

.item-icon {
  font-size: 20px;
  color: Tomato;
  text-align: center;
}

.tool-icon {
  font-size: 20px;
}

.tool-icon:hover {
  cursor: hand;
  cursor: pointer;
}

.icon-off {
  color: grey;
}